var render = function () {
  var _vm$tabs, _vm$template, _vm$template2, _vm$template3, _vm$tabs2, _vm$template4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    ref: "templatesEl",
    class: ['templates', _vm.readOnlyMode ? 'templates--read' : '', ((_vm$tabs = _vm.tabs) === null || _vm$tabs === void 0 ? void 0 : _vm$tabs.length) <= 1 ? 'no-tabs' : '']
  }, [_c('GenproxModal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showLeavingModal,
      expression: "showLeavingModal"
    }],
    staticClass: "leaving-modal",
    attrs: {
      "isLoading": _vm.isLeavingModalLoading,
      "header": "Do you want to save the changes?",
      "description": "It looks like there are some changes made in your template. What do you want to do?"
    },
    on: {
      "cancel": _vm.changeRoute,
      "confirm": _vm.confirmSavingTemplate,
      "close": function close($event) {
        _vm.showLeavingModal = false;
      }
    }
  }), _c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "container-fluid h-full"
  }, [_c('div', {
    staticClass: "row h-full"
  }, [_c('div', {
    staticClass: "col-12 h-full"
  }, [_c('div', {
    staticClass: "templates__body"
  }, [_c('div', {
    staticClass: "templates__actions"
  }, [_c('GenproxButton', {
    staticClass: "mr-4",
    attrs: {
      "outline": true,
      "icon": _vm.ChevronLeftIcon
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("Go back")]), _c('h1', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.routeId ? _vm.readOnlyMode ? 'Preview' : 'Edit' : 'Create') + " " + _vm._s((_vm$template = _vm.template) !== null && _vm$template !== void 0 && _vm$template.isAttachment ? 'attachment' : 'template'))]), _vm.selectedTab === 'content' && !_vm.readOnlyMode ? [_c('file-uploader', {
    attrs: {
      "disabled": !_vm.editable,
      "supported-file-formats": _vm.supportedFileFormats,
      "type": "simple",
      "btnText": _vm.hasTemplateLogo ? 'Change logo' : 'Upload logo',
      "infoText": "File format: jpg, jpeg, png",
      "inverted": true,
      "uploadProgress": _vm.uploadProgress
    },
    on: {
      "input": function input($event) {
        return _vm.addFile($event);
      }
    }
  }), _c('GenproxButton', {
    staticClass: "ml-3 mr-0",
    on: {
      "click": function click($event) {
        _vm.editable = !_vm.editable;
      }
    }
  }, [_vm._v(_vm._s(_vm.editable ? 'Preview' : 'Close preview'))])] : _vm._e(), _vm.selectedTab === 'autenti' ? _c('portal-target', {
    staticClass: "ml-auto",
    attrs: {
      "name": "templateModuleActions"
    }
  }) : _vm._e(), !_vm.readOnlyMode ? _c('GenproxButton', {
    class: [_vm.selectedTab !== 'attachments' ? 'ml-3' : 'ml-auto', 'mr-0'],
    on: {
      "click": function click($event) {
        return _vm.saveTemplate();
      }
    }
  }, [_vm._v(_vm._s(_vm.routeId ? "Save ".concat((_vm$template2 = _vm.template) !== null && _vm$template2 !== void 0 && _vm$template2.isAttachment ? 'attachment' : 'template') : "Create ".concat((_vm$template3 = _vm.template) !== null && _vm$template3 !== void 0 && _vm$template3.isAttachment ? 'attachment' : 'template')))]) : _vm._e()], 2), _c('div', {
    staticClass: "templates-editor"
  }, [((_vm$tabs2 = _vm.tabs) === null || _vm$tabs2 === void 0 ? void 0 : _vm$tabs2.length) > 1 ? _c('div', {
    staticClass: "templates__tabs"
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('div', {
      key: tab.value,
      class: ['templates__tab', tab.disabled ? 'disabled' : '', _vm.selectedTab === tab.value ? 'active' : ''],
      on: {
        "click": function click($event) {
          return _vm.changeTab(tab);
        }
      }
    }, [_vm._v(_vm._s(tab.label))]);
  }), 0) : _vm._e(), _c('div', {
    class: ['templates-editor__inner', _vm.selectedTab === 'content' ? 'overflow-hidden' : '']
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedTab === 'content',
      expression: "selectedTab === 'content'"
    }],
    staticClass: "templates-editor__page"
  }, [_c('editor-component', {
    ref: "editorEl",
    attrs: {
      "editable": _vm.editable && !_vm.readOnlyMode,
      "content": '',
      "simple": _vm.simple,
      "readOnlyMode": _vm.readOnlyMode,
      "template": _vm.template
    },
    on: {
      "update": _vm.handleContentUpdate,
      "watermarkChange": _vm.updateWatermarkData
    },
    scopedSlots: _vm._u([{
      key: "additionalHeaderFields",
      fn: function fn() {
        return [_c('div', {
          staticClass: "wysiwyg-editor__info-field"
        }, [_c('sygni-input', {
          staticClass: "text-left",
          attrs: {
            "label": "TITLE",
            "validation": _vm.$v.template.title,
            "disabled": _vm.readOnlyMode || _vm.routeId
          },
          on: {
            "blur": function blur($event) {
              return _vm.handleInput(_vm.$v.template.title);
            }
          },
          model: {
            value: _vm.template.title,
            callback: function callback($$v) {
              _vm.$set(_vm.template, "title", $$v);
            },
            expression: "template.title"
          }
        })], 1), _c('div', {
          staticClass: "wysiwyg-editor__info-field"
        }, [_c('sygni-input', {
          staticClass: "text-left",
          attrs: {
            "label": "DESCRIPTION",
            "validation": _vm.$v.template.description,
            "disabled": _vm.readOnlyMode
          },
          on: {
            "blur": function blur($event) {
              return _vm.handleInput(_vm.$v.template.description);
            }
          },
          model: {
            value: _vm.template.description,
            callback: function callback($$v) {
              _vm.$set(_vm.template, "description", $$v);
            },
            expression: "template.description"
          }
        })], 1)];
      },
      proxy: true
    }, !_vm.readOnlyMode ? {
      key: "sidebar",
      fn: function fn() {
        return [_c('div', {
          staticClass: "wysiwyg-editor__tags"
        }, [_c('div', {
          staticClass: "wysiwyg-editor__tags-header"
        }, [_c('sygni-input', {
          staticClass: "text-left",
          attrs: {
            "label": "INSERT INTO DOCUMENT",
            "clearable": true,
            "placeholder": "Search for tag"
          },
          model: {
            value: _vm.tagSearch,
            callback: function callback($$v) {
              _vm.tagSearch = $$v;
            },
            expression: "tagSearch"
          }
        })], 1), _vm._l(_vm.filteredTags, function (tag) {
          return _c('div', {
            key: tag,
            staticClass: "tag",
            on: {
              "click": function click($event) {
                return _vm.insertTag(tag);
              }
            }
          }, [_c('div', {
            staticClass: "tag__value"
          }, [_vm._v("@" + _vm._s(tag))])]);
        })], 2)];
      },
      proxy: true
    } : null], null, true)
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedTab === 'attachments',
      expression: "selectedTab === 'attachments'"
    }],
    staticClass: "templates-editor__page"
  }, [!((_vm$template4 = _vm.template) !== null && _vm$template4 !== void 0 && _vm$template4.isAttachment) ? _c('attachments', {
    ref: "attachments",
    attrs: {
      "showAutentiAction": _vm.showAutentiAttachmentDetails,
      "template": _vm.template,
      "attachments": _vm.attachmentsToUpload,
      "showPreviewAlways": true,
      "readOnlyMode": _vm.readOnlyMode,
      "editMode": _vm.attachmentsEditMode
    },
    on: {
      "update": _vm.handleAttachmentsUpdate,
      "openAttachment": _vm.openAttachmentPreview
    }
  }) : _vm._e()], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedTab === 'autenti',
      expression: "selectedTab === 'autenti'"
    }],
    staticClass: "templates-editor__page"
  }, [_c('autenti-form', {
    ref: "autentiForm",
    attrs: {
      "mode": "single",
      "hasAutenti": _vm.hasAutenti,
      "templateId": _vm.routeId || null,
      "showSaveButton": false,
      "isDisabled": _vm.isAutentiDisabled
    },
    on: {
      "formUpdated": function formUpdated($event) {
        _vm.hasChanges = true;
      }
    }
  })], 1)])])])])])]), _c('attachment-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showAttachmentPreviewModal,
      expression: "showAttachmentPreviewModal"
    }],
    attrs: {
      "attachment": _vm.selectedAttachment
    },
    on: {
      "close": _vm.closeAttachmentPreviewModal
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }